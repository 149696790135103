import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionManagementModel, SubManangementStepModel,SubManangementOptModel } from '../../models/subscription-management.model';

import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";

@Injectable({
  providedIn: 'root'
})
export class DataManagementService {
  private data: any;

  constructor(private apiService: ApiService, private dataService: DataService) {}
  
  addDashboard(data) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  getCurrentDataManagementObj() {
      return this.dataService.getCurrentDBObject();
  }

  setCurrentDataManagementObj(obj) {
    this.dataService.setCurrentDBObject(obj);
  }

  addNewConnection(obj) {
    this.dataService.addNewConnection(obj);
  }

  getAllAIDeshboards(): SubscriptionManagementModel[] {
    return this.dataService.getAllAIDeshboards();
  }

  getAllSteps(): Observable<any> {
    let apiUrl = '/assets/data/subManagementSteps.data.json';
    return this.apiService.getJSONfromLocal(apiUrl);
  }

  getAllOptions(): Observable<any> {
    let apiUrl = '/assets/data/subManagementOptions.data.json';
    return this.apiService.getJSONfromLocal(apiUrl);
  }

  
}